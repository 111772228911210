import React from "react";
import Layout from "../../../other/layout";
import BankingProducts from "../../../components/AC-StaticPages/products/BankingProducts";
import SEO from "../../../other/seo";

export default function bankingProducts() {
  return (
    <Layout>
      <SEO title="Banking Products" />
      <BankingProducts />
    </Layout>
  );
}
