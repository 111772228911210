import React from "react";
import { Link } from "gatsby";
import BankingProducts_Hero from "../../../assets/img/banking-products-hero.jpg";
import cheque_envelopes from "../../../assets/img/cheque-envelopes.jpg";
import deposit_slips from "../../../assets/img/deposit-slips.jpg";
import deposit_bags from "../../../assets/img/deposit-bags.jpg";
import stamps from "../../../assets/img/stamps.jpg";

import styles from "../style/styles.module.css";

export default function BankingProducts() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.content}>
          <h1>Banking Products</h1>
          <h2>Don't stop at cheques.</h2>
          <p className={styles.productParag}>
            Simplify your customers banking routine with convenient deposit
            tickets, endorsement stamps and security deposit bags.
          </p>
          <img
            className={styles.hero}
            src={BankingProducts_Hero}
            alt="Banking products: Cheques, forms, office supplies Image"
          />

          <p className={styles.productMargin}>
            Banking products for quicker and easier banking.
          </p>
          <hr style={{ border: "1px solid #d3dce2", width: "100%" }} />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.content}>
          <h2>Product Category Highlights</h2>
          <div className={styles.cards}>
            <div className={styles.card}>
              <img src={cheque_envelopes} alt="Cheque envelopes Image"></img>
              <Link to="/products/banking-products/cheque-envelopes/">
                Cheque Envelopes
              </Link>
            </div>
            <div className={styles.card}>
              <img src={deposit_slips} alt="Deposit slips Image"></img>
              <Link to="/products/banking-products/deposit-slips/">
                Deposit Slips
              </Link>
            </div>
            <div className={styles.card}>
              <img src={deposit_bags} alt="Deposit Bags Image"></img>
              <Link to="/products/banking-products/deposit-bags/">
                Deposit Bags
              </Link>
            </div>
            <div className={styles.card}>
              <img src={stamps} alt="Stamp Image"></img>
              <Link to="/products/banking-products/stamps/">Stamps</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
